<template>
    <div>
        <v-app>
            <navigation :color="color" :flat="flat" :textColor="textColor" :logoCompleto="logoCompleto" />
            <v-main>
                <v-carousel hide-delimiters :show-arrows="false">
                    <v-carousel-item>
                        <v-img :src="itemsFAQ.faq_banner" contain />
                    </v-carousel-item>
                </v-carousel>
                <!-- Img Misión -->
                <v-row class="my-10" justify="center">
                    <v-col cols="12" class="text-center">
                        <v-img class="mx-auto" max-width="173" max-height="103" src="~@/assets/img/simplelogo.png"></v-img>
                        <div>
                            <p class="text-h6 secondary--text font-weight-bold text-center">Aquí encontrarás respuestas a la mayoría de las preguntas que pueden surgirte con relación a la solución</p>
                        </div>
                    </v-col>
                </v-row>
                <!-- /Img Misión -->

                <v-container>
                    <v-row>
                        <v-col cols="12" sm="12" md="12">
                            <v-card
                                flat
                                class="mx-auto transparent"
                                color=""
                                :loading="loading"
                            >
                                <v-sheet class="pa-4  secondary darken-2 rounded-pill">
                                    <v-text-field
                                        v-model="search"
                                        label="Ej. ¿Cómo funciona esta solución?"
                                        dark
                                        placeholder="Ej. ¿Cómo funciona esta solución?"
                                        solo-inverted
                                        hide-details
                                        clearable
                                        rounded
                                        clear-icon="mdi-close-circle-outline"
                                    ></v-text-field>
                                </v-sheet>
                                <v-divider></v-divider>
                                <v-expand-transition>
                                <v-list
                                    v-if="autocompleteResults.length > 0"
                                    class="accent lighten-3"
                                >
                                    <v-list-item
                                        v-for="item in autocompleteResults"
                                        :key="item.id"
                                    >
                                        <v-list-item-content>
                                            <v-list-item-title class="primary--text">{{ item.title }}</v-list-item-title>
                                            <v-list-item-subtitle
                                                v-for="(child, i) in item.children"
                                                :key="i"
                                            >
                                                <h4 v-html="child.title"></h4>
                                                <div v-html="child.description"></div>
                                            </v-list-item-subtitle>
                                            <v-divider></v-divider>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-list>
                                </v-expand-transition>
                            </v-card>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" sm="12" md="4">
                            <v-card
                                class="mx-auto transparent"
                                flat
                            >
                                <v-list
                                    three-line
                                    class="transparent"
                                >
                                    <v-list-item-group
                                        v-model="selectedItem"
                                        color="accent"
                                        active-class="customButton white--text"
                                    >
                                        <v-tooltip
                                            top
                                            v-for="item in itemsFAQ.faq_questions"
                                            :key="item.id"
                                            color="secondary"
                                        >
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-list-item
                                                    v-bind="attrs"
                                                    v-on="on"
                                                    @click="setCategoria(item)"
                                                >
                                                    <v-list-item-icon>
                                                        <v-icon>{{ item.icon }}</v-icon>
                                                    </v-list-item-icon>
                                                    <v-list-item-content>
                                                        <v-list-item-subtitle class=" text-h6">{{ item.title }}</v-list-item-subtitle>
                                                    </v-list-item-content>
                                                </v-list-item>
                                            </template>
                                            <span>Selecciona una opción para mostrar más preguntas</span>
                                        </v-tooltip>
                                    </v-list-item-group>
                                </v-list>
                            </v-card>
                        </v-col>
                        
                        <v-col cols="12" sm="12" md="8">
                            <v-card :loading="loading" class="mb-4">
                                <v-card-text>
                                    <v-expansion-panels
                                        focusable
                                        inset
                                        class="pb-5"
                                    >
                                        <v-expansion-panel
                                            v-for="(item,i) in currentCategoria.children"
                                            :key="i"
                                        >
                                            <v-expansion-panel-header>
                                                {{ item.title }}
                                                <template v-slot:actions>
                                                    <v-icon color="dark">
                                                    mdi-plus
                                                    </v-icon>
                                                </template>
                                            </v-expansion-panel-header>
                                            <v-divider></v-divider>
                                            <v-expansion-panel-content>
                                                <div class="text-left" v-html="item.description"></div>
                                            </v-expansion-panel-content>
                                        </v-expansion-panel>
                                    </v-expansion-panels>
                                </v-card-text>
                            </v-card>
                            <v-row class="text-center" justify="center">
                                <v-col cols="12" md="12">
                                    <div class="pa-1 secondary  white--text rounded-pill">
                                        Si tienes alguna otra consulta, envíanos un correo electrónico
                                        <v-btn
                                            class="ml-10 primary--text"
                                            dark
                                            rounded
                                            color="blue-grey lighten-5"
                                            :loading="loading"
                                            href="mailto: abc@example.com"
                                        >
                                            escríbenos
                                        </v-btn>
                                    </div>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>

                </v-container>

            </v-main>

            <v-scale-transition>
                <v-btn fab v-show="fab" v-scroll="onScroll" dark fixed bottom right color="secondary" @click="toTop">
                    <v-icon>mdi-arrow-up</v-icon>
                </v-btn>
            </v-scale-transition>
            <customFooter></customFooter>
        </v-app>
    </div>
</template>
  
<script>
import axios from "axios";
import { mapState, mapActions } from "vuex";
import AOS from 'aos';
import navigation from "@/modules/landing/components/Navigation.vue";
import customFooter from "@/modules/shared/layouts/Footer.vue";

export default {
    name: "Nosotros",
    components: {
        navigation,

        customFooter,
    },

    data: () => ({
        logoCompleto: null,
        fab: null,
        color: "primary",
        textColor: "white",
        flat: null,
        search: "",
        autocompleteResults: [],
        selectedItem: 0,
        currentCategoria: {
            children: []
        },
    }),

    created() {
        this.getItemsFAQ()
        .then(() =>{
            this.currentCategoria = this.itemsFAQ.faq_questions[0];
        });

        const top = window.pageYOffset || 0;
        if (top <= 60) {
            this.color = "primary";
            this.flat = true;
        }
    },
    computed: {
        ...mapState("loader", ["loading"]),
        ...mapState("landing", ["itemsFAQ"]),
    },
    mounted() {
        // Actualiza AOS después de que Vue ha renderizado el componente
        this.$nextTick(() => {
            AOS.refresh();
        });
    },
    methods: {
        ...mapActions({
            getItemsFAQ: "landing/getItemsFAQ",
        }),
        onScroll(e) {
            if (typeof window === "undefined") return;
            const top = window.pageYOffset || e.target.scrollTop || 0;
            this.fab = top > 60;
        },
        toTop() {
            this.$vuetify.goTo(0);
        },
        setCategoria(item) {
            this.currentCategoria = item;
        },
        getAutocompleteResults(query) {
            let me = this;
            return axios
            .get(`faq.php?query=${query}`)
            .then((response) => {
                console.log("getAutocompleteResults: ", response);
                if (response.data) {
                    this.autocompleteResults = response.data;
                }
            })
            .catch(function (err) {
                console.log("err:", err);
                me.$toast.error(`Ha ocurrido un problema al cargar al listado`);
            });
        },
    },
    watch: {
        fab(value) {
            if (value) {
                this.color = "dark";
                this.textColor = "white";
                this.flat = false;
                this.logoCompleto = false;
            } else {
                this.color = "primary";
                this.textColor = "white";
                this.flat = true;
                this.logoCompleto = true;
            }
        },
        search: function(newVal, oldVal) {
            console.log(oldVal);
            // Llamar a la función de autocompletado cada vez que cambie el valor de búsqueda
            if (newVal.length >= 5) {
                this.getAutocompleteResults(newVal);
            } else {
                // Si la longitud del término de búsqueda es menor a 5, limpiar los resultados
                this.autocompleteResults = [];
            }
        },
    }
};
</script>
  
<style scoped>
#app {
    background-image: url("~@/assets/img/home_background.png");
    background-attachment: fixed;
    background-position: center;
    background-size: cover;
    z-index: 10;
}
</style>